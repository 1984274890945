.hr {
  height: 2px;
  color: rgb(224, 223, 223);
  background-color: #dbd8d8;
  width: 100%;
}

.font__size__14 {
  font-size: 0.875rem;
}
