.font__size__25 {
  font-size: 1rem;
}

.margin__top__30 {
  margin-top: 2.125rem;
}

@media (max-width: 768px) {
  .remove_text_mobile {
    display: none;
  }
  .font__size__25 {
    font-size: 0.75rem;
  }
  
}
