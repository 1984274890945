/*--------------------- Landing Img styles ---------------------*/
.landing__img__container {
  width: 100%;
  position: relative;
  display: inline-block;
}

/*--------------------- Headline Styles ---------------------*/
.headline__container {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headline__container__desktop {
  position: absolute;
  width: 100%;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header__title__desktop {
  font-weight: bolder;
  background-color: #ffca00;
  border-radius: 10px;
  border: none;
  color: black;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 35px;
}
.header__title {
  font-size: 50px;
  color: var(--dark-golden);
  font-weight: bolder;
}
.typist__title {
  color: black;
  font-weight: bolder;
  margin-top: 24px;
  font-size: 24px;
}

/*--------------------- Media queries ---------------------*/
@media screen and (max-width: 1170px) {
  .header__title__desktop {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .header__title {
    font-size: 25px;
    font-weight: 900 !important;
  }

  .typist__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .header__title__desktop {
    font-size: 22px;
    font-weight: 900;
    padding: 2px 6px;
    border-radius: 6px;
  }
  .mobileBtn {
    font-size: 18px !important;
    margin-left: 0 !important;
    padding: 6px 8px !important;
    border-radius: 3px !important;
    margin-right: 24px !important;
  }
  .typist__title {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 15px;
    margin-left: 0px;
    padding-right: 10px;
  }
}
