.image {
  border-radius: 0.6rem;
  padding: 0.313rem;
}
@media screen and (max-width: 768px) {
    .name__font__size {
      font-size: 0.75rem; /* 12px*/
    }
  
    .img {
      margin: 0 2px;
    }
  }
  @media screen and (max-width: 320px) {
    .name__font__size {
      font-size: 0.625rem; /* 10px*/
    }
  }