.Value {
  color: var(--light-golden);
  font-weight: bolder;
  font-size: 2rem;
}

.Title {
  height: 23px;
}

.BottomLine {
  border-color: var(--light-golden);
  border-top-width: 3px;
  width: 40%;
}
